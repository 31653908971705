import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer bg-[#C03E43] text-white">
      <div className="md:flex justify-center p-10 md:p-24">
        {/* Location, Phone, Email */}
        <div className="flex flex-col gap-5 mb-8 md:mb-0 md:w-2/6">
          <div className="flex items-center gap-3">
            <img
              src="../assets/home.png"
              alt="Home"
              className="w-8 h-8"
              loading="lazy"
              style={{ width: "32px", height: "32px" }}
            />
            <p>Yogashray Sewayatan (Naturopathy & Yoga Meditation Centre)</p>
          </div>
          <div className="flex items-center gap-3">
            <img
              src="../assets/location.png"
              alt="Location"
              className="w-8 h-8"
              loading="lazy"
              style={{ width: "32px", height: "32px" }}
            />
            <p>Keshopura, Bhankrota, Ajmer Road, Jaipur</p>
          </div>
          <div className="flex items-center gap-3">
            <img
              src="../assets/phone.png"
              alt="Phone"
              className="w-8 h-8"
              loading="lazy"
              style={{ width: "32px", height: "32px" }}
            />
            <p>+919772947555 / +919772956555</p>
          </div>
          <div className="flex items-center gap-3">
            <img
              src="../assets/mail.png"
              alt="Email"
              className="w-8 h-8"
              loading="lazy"
              style={{ width: "32px", height: "32px" }}
            />
            <p>info@yogashraysewayatan.com</p>
          </div>
        </div>

        {/* Tripadvisor Section */}
        <div className="flex flex-col gap-5 mb-8 md:mb-0 md:w-2/6 md:items-center">
          <div className="flex flex-col gap-3 items-center">
            <div className="flex items-center gap-3">
              <p>Tripadvisor</p>
              <img
                src="../assets/tripadvisor.png"
                alt="Tripadvisor"
                className="footericon w-8 h-8"
                loading="lazy"
                style={{ width: "32px", height: "32px" }}
              />
            </div>
            <img
              src="../assets/stars.png"
              alt="Stars"
              className="w-20"
              loading="lazy"
              style={{ width: "80px", height: "auto" }}
            />
          </div>
        </div>

        {/* Social Media Icons */}
        <div className="flex flex-col gap-5 mb-8 md:mb-0 md:w-2/6 md:items-center">
          <p className="text-xl">Follow Us On</p>
          <div className="flex gap-3 md:ml-20">
            {[
              {
                href: "https://www.facebook.com/yogashraysewayatan/",
                src: "../assets/facebook.png",
                alt: "Facebook",
              },
              {
                href: "https://www.instagram.com/yogashraysewayatan/",
                src: "../assets/instagram.png",
                alt: "Instagram",
              },
              {
                href: "https://wa.me/9772398555",
                src: "../assets/whatsapp.png",
                alt: "WhatsApp",
              },
              {
                href: "https://www.youtube.com/channel/UCrLtb_GTc8OfQXMseTCaH4w/videos",
                src: "../assets/youtube.png",
                alt: "YouTube",
              },
              {
                href: "https://www.tripadvisor.in/Attraction_Review-g304555-d14047272-Reviews-Yogashray_Sewayatan-Jaipur_Jaipur_District_Rajasthan.html",
                src: "../assets/tripadvisor.png",
                alt: "Tripadvisor",
              },
            ].map((item, index) => (
              <a key={index} href={item.href}>
                <img
                  className="footericon w-8 h-8"
                  src={item.src}
                  alt={item.alt}
                  loading="lazy"
                  style={{ width: "32px", height: "32px" }}
                />
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* Privacy Policy */}
      <div className="bg-black/45 p-5">
        <Link to="/privacy-policy">
          <p className="text-center">Privacy Policy</p>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
