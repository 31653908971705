// BookingModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import './BookingModel.css';
import { BASE_URL } from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
Modal.setAppElement('#root');
const notify = () => toast("We will get back to you soon");
const contactNotify = () => toast("Please fill all the details");
const BookingModel = ({ isOpen, onRequestClose }) => {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        place: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        console.log('forrm');
        console.log(formData);
    };

    const handleSubmit = async (e) => {

        // Handle form submission logic here
        console.log(formData.name.length);
        console.log(formData.email.length);
        console.log(formData.phone.length);
        console.log(formData.subject.length);
        console.log(formData.message.length);




        if (formData.name.length > 0 && formData.email.length > 0 && formData.phone.length > 0 && formData.subject.length > 0 && formData.message.length > 0) {
            const data = { name: formData.name, email: formData.email, phone: formData.phone, gender: formData.subject, illness: formData.message, place: formData.place }
            console.log("my data");

            const rawResponse = await fetch(BASE_URL + '/api/v1/contactus/addContact', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const content = await rawResponse.json();

            notify()
            onRequestClose();
            navigate("/thank-you-for-your-inquiry")
        }
        else {
            contactNotify()
            onRequestClose();
        }

        //onRequestClose();
        e.preventDefault();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Online Consultation"
            className="modal"
            overlayClassName="overlay"
            style={{
                content: {
                    maxWidth: '500px', // Adjust the width as needed
                    margin: 'auto', // Center the modal
                },
            }}
        >
            <div className="modal-header">
                <h2 className='text-2xl text-[#c03e43] text-center font-semibold'>Online Consultation</h2>
                <button onClick={onRequestClose} className="close-button">&times;</button>
            </div>
            <div className='mt-4'>
                <div className="form-group">
                    <label htmlFor="name">NAME</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">EMAIL ID :</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">PHONE NO :</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="subject">GENDER</label>
                    <input
                        type="text"
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">ILLNESS</label>
                    <input
                        type="text"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">PLACE & COUNTRY OF RESIDENCE</label>
                    <input
                        type="text"
                        name="place"
                        value={formData.place}
                        onChange={handleChange}
                        required
                    />
                </div>
                <center>
                    <button type="button" onClick={handleSubmit} className='form-btn'>Submit</button>
                </center>
            </div>
        </Modal>

    );
};

export default BookingModel;
