// components/NotFound.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const ThankYou = ({ staticContext }) => {
    const navigate = useNavigate()


    if (staticContext) {
        staticContext.status = 200;
    }
    return (

        <div style={{ marginTop: '25%', marginBottom: '25%' }}>
        
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt", textAlign: "center" }}>
          &nbsp;
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt", textAlign: "center" }}>
          <span style={{ fontFamily: "Calibri, sans-serif", fontSize: "11pt" }} />
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt", textAlign: "center" }}>
          &nbsp;
        </p>
        <p style={{ letterSpacing: "-0.5pt", margin: "0in", textAlign: "center" }}>
          <span
            style={{ fontFamily: '"Calibri Light",sans-serif', fontSize: "28pt" }}
          >
            <i>
              <strong>Namaskar!</strong>
            </i>
          </span>
          <span
            style={{ fontFamily: '"Calibri Light", sans-serif', fontSize: "28pt" }}
          >
            <i>
              <strong>
                <span
                  style={{
                    fontFamily: '"Calibri Light",sans-serif',
                    fontStyle: "normal"
                  }}
                />
              </strong>
            </i>
          </span>
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt" }}>
          <span style={{ fontFamily: "Calibri, sans-serif", fontSize: "11pt" }} />
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt", textAlign: "center" }}>
          <span style={{ fontFamily: "Calibri, sans-serif", fontSize: "11pt" }}>
            Dear Guest,
          </span>
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt" }}>
          <span style={{ fontFamily: "Calibri, sans-serif", fontSize: "11pt" }} />
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt", textAlign: "center" }}>
          <span style={{ fontFamily: "Calibri, sans-serif", fontSize: "11pt" }}>
            Thank you for sending booking inquiry! We are delighted to be your
            preferred Wellness destination. One of our representatives will get in
            touch with you to discuss your booking needs.
          </span>
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt" }}>
          <span style={{ fontFamily: "Calibri, sans-serif", fontSize: "11pt" }} />
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt", textAlign: "center" }}>
          <span style={{ fontFamily: "Calibri, sans-serif", fontSize: "11pt" }}>
            In the meantime, you can explore:{" "}
          </span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.yogashraysewayatan.com/treatment-plans"
          >
            <span style={{ fontFamily: "Calibri, sans-serif", fontSize: "11pt" }}>
              Indoor Treatment Plans
            </span>
          </a>
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt" }}>
          <span style={{ fontFamily: "Calibri, sans-serif", fontSize: "11pt" }} />
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt", textAlign: "center" }}>
          <span style={{ fontFamily: "Calibri, sans-serif", fontSize: "11pt" }}>
            For any kind of assistance please call: +919772947555 /
            +919772956555&nbsp;
          </span>
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt" }}>
          <span style={{ fontFamily: "Calibri, sans-serif", fontSize: "11pt" }} />
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt", textAlign: "center" }}>
          <span style={{ fontFamily: "Calibri, sans-serif", fontSize: "11pt" }}>
            We look forward to welcoming you to Yogashray Sewayatan soon.
          </span>
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt", textAlign: "center" }}>
          <span style={{ fontFamily: "Calibri, sans-serif", fontSize: "11pt" }}>
            With warm regards,
          </span>
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt", textAlign: "center" }}>
          <span style={{ fontFamily: "Calibri, sans-serif", fontSize: "11pt" }}>
            The Yogashray Sewayatan Team
          </span>
        </p>
        <p style={{ lineHeight: "107%", margin: "0in 0in 8pt", textAlign: "center" }}>
          &nbsp;
        </p>
       
       
      </div>
      
    );
};

export default ThankYou;
